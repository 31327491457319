export const NAVBAR_GIFTS_ONE =
  "https://imagedelivery.net/kAv2lbEaDpLeRztonIqp7A/fd05890b-60ea-4765-9996-d90eb2eb9600/public";
export const NAVBAR_GIFTS_TWO =
  "https://imagedelivery.net/kAv2lbEaDpLeRztonIqp7A/b4d0ca40-e789-4e33-7faf-0ee5501efd00/public";
// BANNER CHANGE
export const HERO_BANNER_ONE =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Bakes+Category+Banner.webp";
// Original Banner
// export const HERO_BANNER_ONE =
//   "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Pinch_Hero_Banner_Desktop.webp";
export const HERO_BANNER_TWO =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/homepagebanner_sugarfree.webp";
// Original Banner BANNER
// export const HERO_BANNER_TWO =
//   "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/All+Pastries+Category+Banner+-+728+x+524px.webp";

export const HOMEPAGE_ONE =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/The+cake+concierge+you+need.webp";
export const HOMEPAGE_TWO =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Working%E2%80%99s+tough%2C+but+working+with+us+is+not+2.webp";
export const HOMEPAGE_THREE =
  "https://imagedelivery.net/kAv2lbEaDpLeRztonIqp7A/6e2da097-64bb-45c5-3471-3d17b90d9700/public";
export const HOMEPAGE_FOUR =
  "https://imagedelivery.net/kAv2lbEaDpLeRztonIqp7A/1f7dfa20-9f4c-493b-d09c-e363d3e7c200/public";
export const HOMEPAGE_FIVE =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Working%E2%80%99s+tough%2C+but+working+with+us+is+not+2.webp";
export const HOMEPAGE_SIX =
  "https://pinchbakehouse-stg.s3.ap-southeast-1.amazonaws.com/products/Banner+2.webp";
export const HOMEPAGE_SEVEN =
  "https://imagedelivery.net/kAv2lbEaDpLeRztonIqp7A/b37b7bfa-c948-4880-8253-0eac2ac92400/public";
export const HOMEPAGE_EIGHT =
  "https://pinchbakehouse-stg.s3.ap-southeast-1.amazonaws.com/products/Banner+2.webp";
export const PRODUCT_BIG_IMAGE =
  "https://imagedelivery.net/kAv2lbEaDpLeRztonIqp7A/e69444f2-703f-49c4-0195-f8d9b1e2dc00/public";
export const THROW_PARTY_IMAGE =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/16pcs_Assorted%20Brownie%202-800x800px.webp_1714855054517";
export const CELEBRATION_IMAGE =
  "https://imagedelivery.net/kAv2lbEaDpLeRztonIqp7A/05d5e4d9-63f2-447b-839f-d8095093e400/public";
export const CELEBRATION_IMAGE_1 =
  "https://imagedelivery.net/kAv2lbEaDpLeRztonIqp7A/325719d6-5656-4deb-84b2-f0fce9e85c00/public";
export const ALL_CAKES_BANNER_IMAGE =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Yellow%20Sprinkle%20Cake%202%20-%20800x800px.webp_1714853610430";
export const SEASONAL_CAKE_BANNER_IMAGE =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/categorybanner_christmas_desktop.webp";
export const CUSTOMISED_CAKE_BANNER_IMAGE =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Rainbow%20Cake%202%20-%20800x800px.webp_1714853675960";
export const CLASSIC_CAKE_BANNER_IMAGE =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Classic%20Ondeh%20Ondeh%20Cake%201-800x800px.webp_1714851828544";
export const BIRTHDAY_CAKES =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Yellow%20Sprinkle%20Cake%202%20-%20800x800px.webp_1714853610430";
export const SEND_LOVE =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Popsicle%20cake%202-%20800x800px.webp_1714857034408";
export const CUSTOMISED_CAKES =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Pokemon%203%20-%20800x800px.webp_1714853031554";
export const ALL_CAKES_NAVBAR_IMAGE =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Hello%20Kitty%203%20-%20800x800px.webp_1714853123278";
export const ALL_BAKES_NAVBAR_IMAGE =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/12pcs_Assorted%20Mini%20tarts%202-800x800px.webp_1714856186793";
export const NEW_YEAR_COOKIES =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/menu_cookies.webp";
export const NEW_YEAR_CAKES =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/menu_cakes.webp";
export const BENTO_CAKE =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/bento_banner.webp";
export const CHRISTMAS_TREE_NAVBAR_IMAGE =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/pinchbakehouse_christmas_brownie_tree_2024-0603_1x1%20copy.webp_1730973617813";
// old banner for bakes categories
// export const ALL_PASTRIES_BANNER_IMAGE =
//   "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/All+Pastries+Category+Banner+-+728+x+524px.webp";
export const ALL_PASTRIES_BANNER_IMAGE =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/categorybanner_christmas_desktop.webp";

// BLOGS IMAGES
export const THE_BEST_HALAL =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Yellow%20Sprinkle%20Cake%202%20-%20800x800px.webp_1714853610430";
export const WHAT_MAKES_HALAL =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Fan-Favourites%20Mini%20Bites%203%20-%20800x800px.webp_1714856789630";
export const FIVE_REASONS =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Working%E2%80%99s+tough%2C+but+working+with+us+is+not+2.webp";
export const THE_SWEET_WORLD =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/The+cake+concierge+you+need.webp";
export const LOCAL_CAKE =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Screenshot+2024-09-17+at+8.52.42%E2%80%AFPM.png";
export const ATRICLE_SEVEN_CAKE =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Classic%20Chocolate%20Overload%20Cake%201-800x800px.webp_1714851046151";
export const ATRICLE_SEVEN_CAKE_1 =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Classic%20Salted%20Caramel%20Hazelnut%20Cake%201-800x800px.webp_1714851746385";
export const ATRICLE_SEVEN_CAKE_2 =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Classic%20Ondeh%20Ondeh%20Cake%201-800x800px.webp_1714851828544";
export const CHILDREN_DAY =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/pinchbakehouse_childrensday_banner_desktop.jpg";
export const MAYEE_IMG =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Screenshot+2024-08-19+at+6.58.38%E2%80%AFPM.png";
export const CUSTOMISED_CAKES_BLOG =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Screenshot+2024-08-19+at+4.51.44%E2%80%AFPM.png";
export const CUSTOMISED_CAKES_BLOG_1 =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Screenshot+2024-08-19+at+4.53.43%E2%80%AFPM.png";
export const CUSTOMISED_CAKES_BLOG_2 =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Screenshot+2024-08-19+at+4.53.55%E2%80%AFPM.png";
export const CUSTOMISED_CAKES_BLOG_3 =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Screenshot+2024-08-19+at+4.54.07%E2%80%AFPM.png";
export const CUSTOMISED_CAKES_BLOG_4 =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Screenshot+2024-08-19+at+4.54.16%E2%80%AFPM.png";
export const BLOG_9_IMAGES =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/mainthumbnail_pinchbakehouse_assorted_brownies_16s_3_1x1.jpg_1724917034281";
export const BLOG_10_IMAGES =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Safari%202%20-%20800x800px.webp_1714853433521";
export const BLOG_11_IMAGES =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Rainbow%20Cake%201%20-%20800x800px.webp_1714853675959";
export const BLOG_12_IMAGES =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/pinchbakehouse_strawberry_fields-1078_4x5.jpg_1726023683635";
export const BLOG_13_IMAGES =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/All+Pastries+Category+Banner+-+728+x+524px.webp";
export const BLOG_13_IMAGES_1 =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/Fan-Favourites%20Mini%20Bites%201%20-%20800x800px.webp_1724925822949";
export const BLOG_13_IMAGES_2 =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/pinchbakehouse_assorted_brownies_16s_1_1x1.jpg_1724917034284";
export const BLOG_13_IMAGES_3 =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/1%20%282%29.webp_1719305496438";
export const BLOG_14_IMAGES =
  "https://pinchbakehouse-prod.s3.ap-southeast-1.amazonaws.com/products/pinchbakehouse_strawberryfields_classic_altthumb.webp_1728052430092";
